/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';
@import './theme.scss';

@include mat-core($custom-typography);

@include angular-material-theme($WebGui-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

  .success-snackbar {
    color: white;
    background-color: #06BF6F;
    .mat-button-wrapper{
        color: white;
    }
  }

  .error-snackbar {
    color: white;
    background-color: #F44336;
    .mat-button-wrapper{
        color: white;
    }
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #0072ce;
  }
  
  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #fff;
  }

.mat-form-field.readonly{
  input{
    padding-top:10px;
  }

  .mat-form-field-underline{
    display: none;
    padding-top: 10px;
  }
}

form .mat-icon{
  color: #88c9ff;
}
.mat-tooltip-submitted {
  font-size: 13px;
  width: fit-content;
  background: #05860b !important;
}

.mat-tooltip-pending {
  font-size: 13px;
  width: fit-content;
  background: #07c0f8fb !important;
}
.mat-tooltip-warning {
  font-size: 13px;
  width: fit-content;
  background:orange !important;
}
.mat-tooltip-error {
  font-size: 13px;
  width: fit-content;
  background:red !important;
}
.mat-tooltip-add {
  font-size: 13px;
  width: fit-content;
  background: #c300ff !important;
}
.mat-tab-label{
  opacity: 1 !important;
}

.mat-tab-label.mat-tab-label-active {
  color: #459aff;
}

.dash{
  border-bottom: dashed 2px gray;
}

.mat-header-cell{
  font-size:12px;
  font-weight:500;
}

.spinner{
  background-color:rgba(51, 51, 51, 0.8);
   p{
     color: white;
   }
 }

.mat-raised-button.functional-button,.functional-button{
  color: #1A81E1;
  border: 2px solid #1A81E1;
  margin: 5px;
}

.mat-raised-button.functional-button-icon,.functional-button-icon{
  color: #1A81E1;
  border: 0px;  
  float:right;
  padding-left: 5px;
  text-align:bottom;  
}

.mat-raised-button.functional-button-dark,.functional-button-dark{
  background-color: #1A81E1;
  color: white;
  border: 2px solid #1A81E1;
  margin: 5px;
}
.mat-grid-tile .mat-figure {
  justify-content: flex-start !important ;
  align-items: flex-start !important;
}
.mat-expansion-panel-body {
  padding: 0 !important;
}

.align-right{
  text-align: right;
}

.green-color{
  color: #05860b;
}
.orange-color{
  color: orange;
}
.red-color{
  color: red;
}
.mat-tab-body-content{
  height: 100%;
  overflow: initial !important;
}

button:focus { 
  outline: none; 
}

.mat-button-focus-overlay { 
  background-color: transparent!important; 
}